import * as React from 'react';
import { Footer } from './Footer';
import NavMenu from './NavMenu';

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {
        return (
            <div className='container-fluid'>
                <div className='row'>
                    <NavMenu />
                </div>
                <div className='row maincontent'>
                    <div className='col-sm-3 hide_mobile'>
                        &nbsp;
                    </div>
                    <div className='col-sm-6 contentCol'>
                        {this.props.children}
                    </div>
                    <div className='col-sm-3 hide_mobile'>
                        &nbsp;
                    </div>
                </div>
                <div className='row'>
                    <Footer />
                </div>
            </div>
        );
    }
}