import { createSlice } from '@reduxjs/toolkit'
import { getItems } from '../api/eventListApi';

export type EventItemProps = {
    eventname : string,
    eventdate : string,
    eventsite : string,
    eventspace : string,
    eventweb : string,
    note : string
}

type EventListState = {
    loading: boolean;
    error: any;
    events: EventItemProps[];
}
 
const initialState: EventListState = {
    loading: false,
    error: null,
    events: [
    ]
}
 
const eventListModule = createSlice({
    name: 'events',
    initialState,
    reducers: {
        // 通信を開始した時に呼ぶ関数
        fetchStart(state, action) {
            state.loading = true;
            state.error = null;
        },
        // 通信が失敗した時に呼ぶ関数
        fetchFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        // 通信が成功した時に呼ぶ関数
        fetchSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.events = action.payload;
        }
    }
})
 
export const {
    fetchStart, fetchFailure, fetchSuccess
} = eventListModule.actions
 
export const fetchItems = () => async (dispatch: (arg0: { payload: any; type: string; }) => void) => {
    try {
        dispatch(fetchStart(null));
        dispatch(fetchSuccess(await getItems()));
    } catch (error: any) {
        dispatch(fetchFailure(error.stack));
    }
};


export default eventListModule
