import * as React from 'react';

interface LinkSectionProps {
    title: string;
    url: string;
    description: string;
    imageSrc?: string;
}

export const LinkSection: React.FC<LinkSectionProps> = ({ title, url, description, imageSrc }) => (
    <div className="section">
        <div className="boxItem centerAdjust">
            <h3><a href={url} target="_blank" rel="noopener noreferrer">{title}</a></h3>
            {imageSrc && <p><a href={url} target="_blank" rel="noopener noreferrer"><img src={imageSrc} alt={`${title}バナー`}></img></a></p>}
            <p>{description}</p>
        </div>
    </div>
);
