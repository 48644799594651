import * as React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import Michell from './babies/Michell';
import Petronius from './babies/Petronius';
import Lami from './babies/Lami';
import { TweetButton } from './TweetButton';

export default class Babies extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return <div>
            <div className="section">
                <h1>ねこみみおむつっ子紹介</h1>
                <p className="contentHeader"><TweetButton text="シュージ個人サークル：ねこみみシッター" /></p>
                <p className="text-small">
                    ねこみみシッターに参加しているねこみみおむつっ子たちを紹介します。
                </p>
                <ul>
                    <li><NavLink to="/babies/Michell" activeClassName="selectLinkActive">ミシェル</NavLink></li>
                    <li><NavLink to="/babies/Petronius" activeClassName="selectLinkActive">ピート</NavLink></li>
                    <li><NavLink to="/babies/Lami" activeClassName="selectLinkActive">ラミ</NavLink></li>
                </ul>
            </div>
            <div className="section">
                <Route exact path='/babies' component={ Michell } />
                <Route path='/babies/Michell' component={ Michell } />
                <Route path='/babies/Petronius' component={ Petronius } />
                <Route path='/babies/Lami' component={ Lami } />
            </div>
        </div>;
    }
}
