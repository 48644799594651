import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export default class Petronius extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return <div>
            <div className="section">
                <h2>木崎ラミ</h2>
                <div className="centerAdjust">
                    <img className="profilePhoto" src="/images/diaperbaby01.png" width="624" height="558" alt="木崎ラミ" />
                    <dl>
                        <dt>名前</dt>
                        <dd>木崎ラミ</dd>
                        <dt>出身</dt>
                        <dd>W.O.L国</dd>
                        <dt>生年</dt>
                        <dd>2085年</dd>
                        <dt>好きなおむつ</dt>
                        <dd>テープタイプ紙おむつ</dd>
                    </dl>
                </div>
                <p className="text-small">
                    木崎ラミちゃんは、性別汎性化措置を産まれてすぐに施されるようになった世代のバイオネコミミです。<br />
                    現役の赤ちゃんとしておむつっ子の先輩方と遊ぶのが大好きで、将来を期待されている元気な子です。<br />
                    近頃は学校に通っている歳のねこみみおむつっ子のおむつを替えてあげる練習に熱中しています。
                </p>
            </div>
        </div>;
    }
}
