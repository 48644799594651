import * as React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

const IMAGES: ReactImageGalleryItem[] = [
        {
            original: "/images/babies/michel_nyan_large.png",
            thumbnail: "/images/babies/michel_nyan_400x465.png"
        },
        {
            original: "/images/babies/michel_3d_400x400.png",
            thumbnail: "/images/babies/michel_3d_400x400.png"
        },
        {
            original: "/images/babies/michell400x400.jpg",
            thumbnail: "/images/babies/michell400x400.jpg"
        }
    ];

export class GalleryOfMichell extends React.Component<{}, {}> {
    
    public render() {
        return <ImageGallery items={IMAGES}/>
    }
}
