import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import Babies from './components/Babies';
import * as ReactGA from 'react-ga';

import './custom.scss'
import VrAct from './components/VrAct';
import Link from './components/Link';

ReactGA.initialize('UA-40452003-6',{
    //debug : true
});
ReactGA.pageview(window.location.pathname + window.location.search);

export default () => (
    <Layout>
        <div className="mainContentWrap">
            <Route exact path='/' component={Home} />
            <Route path='/about' component={ About } />
            <Route path='/babies' component={ Babies } />
            <Route path='/vract' component={ VrAct } />
            <Route path='/link' component={ Link } />
        </div>
    </Layout>
);
