import * as React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

const IMAGES: ReactImageGalleryItem[] = [
    {
        original: "/images/vract/full/E1MuM5xWEAgDHUG.png",
        thumbnail: "/images/vract/thumb/E1MuM5xWEAgDHUG.png"
    },
    {
        original: "/images/vract/full/E1woVDaVcAIeixm.png",
        thumbnail: "/images/vract/thumb/E1woVDaVcAIeixm.png"
    },
    {
        original: "/images/vract/full/E1woYjRVcAMvnLB.png",
        thumbnail: "/images/vract/thumb/E1woYjRVcAMvnLB.png"
    },
    {
        original: "/images/vract/full/E2huXAOVUAAfNO0.png",
        thumbnail: "/images/vract/thumb/E2huXAOVUAAfNO0.png"
    },
    {
        original: "/images/vract/full/E2Uh2bsUYAUwTsE.png",
        thumbnail: "/images/vract/thumb/E2Uh2bsUYAUwTsE.png"
    },
    {
        original: "/images/vract/full/E9tvs8JUcAUw4Xc.png",
        thumbnail: "/images/vract/thumb/E9tvs8JUcAUw4Xc.png"
    },
    {
        original: "/images/vract/full/EnQRZokUYAI39wy.png",
        thumbnail: "/images/vract/thumb/EnQRZokUYAI39wy.png"
    },
    {
        original: "/images/vract/full/Es6Q-41U0AI2Pnd.png",
        thumbnail: "/images/vract/thumb/Es6Q-41U0AI2Pnd.png"
    },
    {
        original: "/images/vract/full/Es6UIetUYAAkA2y.png",
        thumbnail: "/images/vract/thumb/Es6UIetUYAAkA2y.png"
    },
    {
        original: "/images/vract/full/EsrRnxoUUAEEjfl.png",
        thumbnail: "/images/vract/thumb/EsrRnxoUUAEEjfl.png"
    },
    {
        original: "/images/vract/full/EVpWHCwU0AAFdFH.png",
        thumbnail: "/images/vract/thumb/EVpWHCwU0AAFdFH.png"
    },
    {
        original: "/images/vract/full/EwH_bihXIAAl_HZ.png",
        thumbnail: "/images/vract/thumb/EwH_bihXIAAl_HZ.png"
    },
    {
        original: "/images/vract/full/EzgZcQjUUAY5kpX.png",
        thumbnail: "/images/vract/thumb/EzgZcQjUUAY5kpX.png"
    },
    {
        original: "/images/vract/full/EzgZV0jUUAsFNy1.png",
        thumbnail: "/images/vract/thumb/EzgZV0jUUAsFNy1.png"
    },
    {
        original: "/images/vract/full/Ezt0vsHUYAM1jfg.png",
        thumbnail: "/images/vract/thumb/Ezt0vsHUYAM1jfg.png"
    },
    {
        original: "/images/vract/full/FA6fXscUUAQoPQW.png",
        thumbnail: "/images/vract/thumb/FA6fXscUUAQoPQW.png"
    },
    {
        original: "/images/vract/full/FA6fZmuVUAY24fG.png",
        thumbnail: "/images/vract/thumb/FA6fZmuVUAY24fG.png"
    },
    {
        original: "/images/vract/full/FBgqe3xVkAACveH.png",
        thumbnail: "/images/vract/thumb/FBgqe3xVkAACveH.png"
    },
    {
        original: "/images/vract/full/FBgrKPXVQAgQOdw.png",
        thumbnail: "/images/vract/thumb/FBgrKPXVQAgQOdw.png"
    },
    {
        original: "/images/vract/full/FBGsk67VkAQlabl.png",
        thumbnail: "/images/vract/thumb/FBGsk67VkAQlabl.png"
    },
    {
        original: "/images/vract/full/FEuc1iFaQAM61nC.png",
        thumbnail: "/images/vract/thumb/FEuc1iFaQAM61nC.png"
    },
    {
        original: "/images/vract/full/FEudK9xaMAArcWs.png",
        thumbnail: "/images/vract/thumb/FEudK9xaMAArcWs.png"
    },
    {
        original: "/images/vract/full/FF_5A7qacAI9MRI.png",
        thumbnail: "/images/vract/thumb/FF_5A7qacAI9MRI.png"
    },
    {
        original: "/images/vract/full/FF_5EAXakAQGyxx.png",
        thumbnail: "/images/vract/thumb/FF_5EAXakAQGyxx.png"
    },
    {
        original: "/images/vract/full/FGaELfzVIAQ5_Yx.png",
        thumbnail: "/images/vract/thumb/FGaELfzVIAQ5_Yx.png"
    },
    {
        original: "/images/vract/full/FGahEo4VgAAzHjY.png",
        thumbnail: "/images/vract/thumb/FGahEo4VgAAzHjY.png"
    },
    {
        original: "/images/vract/full/FGGHGfEVEAY0i9g.png",
        thumbnail: "/images/vract/thumb/FGGHGfEVEAY0i9g.png"
    },
    {
        original: "/images/vract/full/FGLMcviUUAcRUfK.png",
        thumbnail: "/images/vract/thumb/FGLMcviUUAcRUfK.png"
    },
    {
        original: "/images/vract/full/FGLMhVNVgAYVlGO.png",
        thumbnail: "/images/vract/thumb/FGLMhVNVgAYVlGO.png"
    },
    {
        original: "/images/vract/full/FGZ-bITVcAImnUa.png",
        thumbnail: "/images/vract/thumb/FGZ-bITVcAImnUa.png"
    },
    {
        original: "/images/vract/full/FGZ-bVVVgAAnfdL.png",
        thumbnail: "/images/vract/thumb/FGZ-bVVVgAAnfdL.png"
    },
    {
        original: "/images/vract/full/FHluA4VakAA2qa2.png",
        thumbnail: "/images/vract/thumb/FHluA4VakAA2qa2.png"
    },
    {
        original: "/images/vract/full/FHluBIcaUAA9BFs.png",
        thumbnail: "/images/vract/thumb/FHluBIcaUAA9BFs.png"
    },
    {
        original: "/images/vract/full/FIP4iOOaQAYfAe1.png",
        thumbnail: "/images/vract/thumb/FIP4iOOaQAYfAe1.png"
    },
    {
        original: "/images/vract/full/FIP4kV5acAA1AjM.png",
        thumbnail: "/images/vract/thumb/FIP4kV5acAA1AjM.png"
    },
    {
        original: "/images/vract/full/FKCCZcQacAUKpis.png",
        thumbnail: "/images/vract/thumb/FKCCZcQacAUKpis.png"
    },
    {
        original: "/images/vract/full/FKCCZnQaAAA_OKI.png",
        thumbnail: "/images/vract/thumb/FKCCZnQaAAA_OKI.png"
    },
    {
        original: "/images/vract/full/FKCCZR8aQAACgxq.png",
        thumbnail: "/images/vract/thumb/FKCCZR8aQAACgxq.png"
    },
    {
        original: "/images/vract/full/FKCCZzdaIAAVPFt.png",
        thumbnail: "/images/vract/thumb/FKCCZzdaIAAVPFt.png"
    },
    {
        original: "/images/vract/full/FKCLNmJaQAA8VL5.png",
        thumbnail: "/images/vract/thumb/FKCLNmJaQAA8VL5.png"
    },
    {
        original: "/images/vract/full/FKCLNVlacAI2K-a.png",
        thumbnail: "/images/vract/thumb/FKCLNVlacAI2K-a.png"
    },
    {
        original: "/images/vract/full/FKCLNzQaUAsVjVs.png",
        thumbnail: "/images/vract/thumb/FKCLNzQaUAsVjVs.png"
    },
    {
        original: "/images/vract/full/FM7aMjnVIAEnxpE.png",
        thumbnail: "/images/vract/thumb/FM7aMjnVIAEnxpE.png"
    },
    {
        original: "/images/vract/full/FM7ZloqVUAEGT4C.png",
        thumbnail: "/images/vract/thumb/FM7ZloqVUAEGT4C.png"
    },
    {
        original: "/images/vract/full/FMhd1ARaAAEJ2p0.png",
        thumbnail: "/images/vract/thumb/FMhd1ARaAAEJ2p0.png"
    },
    {
        original: "/images/vract/full/FMhWZdlVEAEWGo8.png",
        thumbnail: "/images/vract/thumb/FMhWZdlVEAEWGo8.png"
    },
    {
        original: "/images/vract/full/FMhXc4NUcAUr6V3.png",
        thumbnail: "/images/vract/thumb/FMhXc4NUcAUr6V3.png"
    },
    {
        original: "/images/vract/full/FMhXdEmUcAYwFo3.png",
        thumbnail: "/images/vract/thumb/FMhXdEmUcAYwFo3.png"
    },
    {
        original: "/images/vract/full/FNJt9lEUcAA7wMO.png",
        thumbnail: "/images/vract/thumb/FNJt9lEUcAA7wMO.png"
    },
    {
        original: "/images/vract/full/FQriTDjVsAYLH4L.png",
        thumbnail: "/images/vract/thumb/FQriTDjVsAYLH4L.png"
    },
    {
        original: "/images/vract/full/FQriVOYVsAQMnTh.png",
        thumbnail: "/images/vract/thumb/FQriVOYVsAQMnTh.png"
    },
    {
        original: "/images/vract/full/FVdr2BYVEAAw0qL.png",
        thumbnail: "/images/vract/thumb/FVdr2BYVEAAw0qL.png"
    },
    {
        original: "/images/vract/full/FVdsDEKVEAEEk6r.png",
        thumbnail: "/images/vract/thumb/FVdsDEKVEAEEk6r.png"
    },
    {
        original: "/images/vract/full/FVdsEv6VsAEiemR.png",
        thumbnail: "/images/vract/thumb/FVdsEv6VsAEiemR.png"
    },
    {
        original: "/images/vract/full/FVdsFtmUEAAcUy4.png",
        thumbnail: "/images/vract/thumb/FVdsFtmUEAAcUy4.png"
    }
];

export class GalleryOfVrAct extends React.Component<{}, {}> {
    
    public render() {
        return <ImageGallery items={IMAGES} thumbnailPosition="left" showIndex lazyLoad />
    }
}
