import * as React from 'react';

export interface TweetButtonState {
    text : String
}

export class TweetButton extends React.Component<TweetButtonState, {}> {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "//platform.twitter.com/widgets.js";
        script.async = true;
        script.onload = () => this.scriptLoaded();
      
        document.body.appendChild(script);
    }

    scriptLoaded() {
        // loadは実行不要
        // twttr.widgets.load();
    }

    render() {
        return (
            <span className="tweetbuttonarea">
                <a ref="tweetButton"
                    href="https://twitter.com/share"
                    className="twitter-share-button"
                    data-text={this.props.text}
                    data-show-count="false">Tweet</a>
            </span>
        )
    }
}
