import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import PartialEventList from './PartialEventList';
import { TweetButton } from './TweetButton';

const Home = () => (
  <div>
    <div className="section">
        <p className="contentHeader"><TweetButton text="シュージ個人サークル：ねこみみシッター" /></p>
      <div className="centerAdjust">
        <img className="mainvisual" src="/images/kemomimi_omutsu_summer.png" alt="ミシェルさんフルカラー写真集けもみみおむつSUMMER表紙" />
      </div>
      <h1 className="centerAdjust homeTitle">ねこみみシッター</h1>
      <p className="centerAdjust text-small">バイオネコミミ専門ベビーシッターサービス同人サークル</p>
    </div>
    <div className="section centerAdjust counter-container">
      <span className="text-small">あなたは</span>
      <img className="counter" src="https://kauntah-svg-fb.vercel.app/counter.svg" alt="ねこみみアクセスカウンター" />
      <span className="text-small">人目のお客様です</span>
    </div>
    <div className="section">
      <h2>最新のイベント情報</h2>
      <Route component={PartialEventList} />
    </div>
  </div>
);

export default connect()(Home);
