import React, { useEffect } from 'react';
import { EventItem } from './EventItem';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../rootReducer'
import { fetchItems } from '../modules/eventListModule';

const PartialEventList: React.FC = () => {
    const dispatch = useDispatch();
    const { events, loading, error } = useSelector((state: RootState) => state.eventList)
    var displayNum:number = 3;

    useEffect(() => {
        // fetchItemsを実行
        dispatch(fetchItems());
    }, [dispatch]);

    if (loading) return <p>...loading</p>;
    if (error) return <p>{error}</p>;

        return(
            <div className='section'>
                <div className='eventlist'>
                    {
                        events.length <= 0 ? '登録されたイベントはありません。':
                        events.slice(0, displayNum).map(event =>
                            <EventItem eventname={event.eventname} eventdate={event.eventdate} eventsite={event.eventsite} eventspace={event.eventspace} eventweb={event.eventweb} note={event.note} />
                        )
                    }
                </div>
            </div>
        );
}

export default PartialEventList
