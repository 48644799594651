import { combineReducers } from "@reduxjs/toolkit"
import { connectRouter } from "connected-react-router"
import createBrowserHistory from "history/createBrowserHistory";
import eventListModule from './modules/eventListModule';
 
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
export const history = createBrowserHistory({ basename: baseUrl });

const rootReducer = combineReducers({
    router: connectRouter(history),
    eventList: eventListModule.reducer
})
 
export type RootState = ReturnType<typeof rootReducer>
 
export default rootReducer
