import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TweetButton } from './TweetButton';

export default class About extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return <div>
            <div className="section">
                <h1>ねこみみシッターについて</h1>
                <p className="contentHeader"><TweetButton text="シュージ個人サークル：ねこみみシッター" /></p>
                <p className="text-small">
                    バイオネコミミ専門ベビーシッターサービス同人サークル「ねこみみシッター」は、シュージの個人サークルです。<br />
                    「布と紙」グループ傘下のサークルとして、ネコミミおむつっ子作品を世に送り出すべく日夜活動しています。
                </p>
            </div>
            <div className="section">
                <h2>スタッフ</h2>

                <div className="boxItem centerAdjust">
                    <h3>シュージ：ねこみみシッター主催</h3>
                    <p className="centerAdjust"><img src="/images/igura_ico.png" width="146" height="146" alt="シュージのアイコン" /></p>
                    <dl>
                        <dt>twitter</dt>
                        <dd><a href="https://twitter.com/feline_babies" target="_blank" rel="noopener noreferrer">feline_babies</a></dd>
                        <dt>pixiv</dt>
                        <dd><a href="https://www.pixiv.net/member.php?id=50306" target="_blank" rel="noopener noreferrer">50306</a></dd>
                        <dt>ベビールックポータル</dt>
                        <dd><a href="http://omport.cc/" target="_blank" rel="noopener noreferrer">omport.cc</a></dd>
                        <dt>好きなおむつ</dt>
                        <dd>テープタイプ紙おむつ</dd>
                    </dl>
                    <p>
                        W.O.L国出身のバイオネコミミ。<br />
                        ゲームプログラマとしてゲームを製作しつつ、ねこみみシッターとしてネコミミ赤ちゃんたちの面倒を見る日々を送る。<br />
                        おむつファッションブランド「フィラインベイビーズ」のシステム開発にも携わっており、顧客のネコミミ赤ちゃんファッションに活用してもらうべく勉強中。
                    </p>
                </div>
            </div>
        </div>;
    }
}
