import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { GalleryOfVrAct } from './GalleryOfVrAct';
import { TweetButton } from './TweetButton';

export default class VrAct extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return <div>
            <div className="section">
                <h1>VRけもみみおむつ活動</h1>
                <p className="contentHeader"><TweetButton text="シュージ個人サークル：ねこみみシッター" /></p>
                <p className="text-small">
                    VRけもみみおむつ活動は「けもみみおむつっ子かわいいよね」の理念の元行われている全年齢対象のファッション表現活動です。<br />
                    ファッションおむつの位置づけとしては「超かわいい部屋着」あたりのポジションを目指しています。
                </p>
            </div>
            <div className="section">
                <h2>ギャラリー</h2>
                <div className="ImageGalleryContainer">
                    <GalleryOfVrAct />
                </div>
            </div>
        </div>;
    }
}
