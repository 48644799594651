import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export default class Petronius extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return <div>
            <div className="section">
                <h2>ピート</h2>
                <div className="centerAdjust">
                    <img className="profilePhoto" src="/images/babies/petronius400x400.png" width="400" height="400" alt="ピート" />
                    <dl>
                        <dt>名前</dt>
                        <dd>若宮ペトロニウス</dd>
                        <dt>出身</dt>
                        <dd>W.O.L国</dd>
                        <dt>生年</dt>
                        <dd>2082年</dd>
                        <dt>好きなおむつ</dt>
                        <dd>テープタイプ紙おむつ</dd>
                    </dl>
                </div>
                <p className="text-small">
                    第一世代バイオネコミミのピート君は、一度おむつを卒業してから趣味のおむつを再開しました。<br />
                    今ではおむつの楽しさを皆さんに伝えるために、twitterで<a href="https://twitter.com/diaperchangebot" target="_blank" rel="noopener noreferrer">おむつ交換を体験できるアカウント</a>を開設しています。<br />
                    是非皆さんもピート君のおむつを交換してあげてくださいね。
                </p>
            </div>
        </div>;
    }
}
