import * as React from 'react';
import * as eventListModule from '../modules/eventListModule';

export class EventItem extends React.Component<eventListModule.EventItemProps, {}> {
    public render() {
        return <div className='eventItem'>
            <h3>{this.props.eventname}</h3>
            <p className='eventDate'>開催日時：{this.props.eventdate}</p>
            <p className='eventSite'>開催場所：{this.props.eventsite}</p>
            <p className='eventSite'>イベントスペース：{this.props.eventspace}</p>
            <p className='evwntWebUrl'>イベント公式サイト：{this.props.eventweb}</p>
            <p>備考：<br />{this.props.note}</p>
        </div>;
    }
}

