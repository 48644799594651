import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LinkSection } from './commonComponents/LinkSection';
import { TweetButton } from './TweetButton';

export default class About extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return <div>
            <div className="section">
                <h1>リンク</h1>
                <p className="contentHeader"><TweetButton text="シュージ個人サークル：ねこみみシッター" /></p>
                <p className="text-small">
                    お世話になっているWEBサイトへのリンク集
                </p>
            </div>
            <LinkSection
                    title="BabyLook.net"
                    url="https://www.babylook.net/"
                    description="べぃびぃるっくどっとねっと"
                />
            <LinkSection
                title="オーネグス"
                url="http://ohnegs.blogspot.com/"
                description="東方CJD＆おむつ・お漏らし同人サークル　オーネグスのブログです。"
                imageSrc="/images/banner/ohnegus.png"
            />
            <LinkSection
                title="はるとりみなと"
                url="https://harutori.org/"
                description="ヴォストクさんのサークル『はるとりみなと』ウェブサイトのです。"
                imageSrc="https://harutori.org/link/image/banner/self/harumina.png"
            />
            <LinkSection
                title="unagi's baby room"
                url="https://unagi-abdl.site/"
                description="うなぎさんの個人ウェブサイトです。"
            />
        </div>;
    }
}
