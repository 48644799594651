import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { GalleryOfMichell } from './GalleryOfMichell';

export default class Michell extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return <div>
            <div className="section">
                <h2>ミシェル</h2>
                <div className="ImageGalleryContainer">
                    <GalleryOfMichell />
                </div>
                <div className="centerAdjust">
                    <dl>
                        <dt>名前</dt>
                        <dd>ミシェル・カツマタ・フェリクス</dd>
                        <dt>出身</dt>
                        <dd>南アメリカ共和国</dd>
                        <dt>生年</dt>
                        <dd>2112年</dd>
                        <dt>好きなおむつ</dt>
                        <dd>パンツタイプ紙おむつ</dd>
                    </dl>
                </div>
                <p className="text-small">
                    ミシェルは、南アメリカ共和国でおむつCMモデルを10年間務めているベテランおむつっ子です。<br />
                    20年、30年とおむつファッションの最先端を求められる道を目指して、おむつっ子としての可愛さに磨きをかけています。
                </p>
            </div>
        </div>;
    }
}
