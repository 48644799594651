import * as React from 'react';

export class Footer extends React.Component<{}, {}> {
    public render() {
        return(
        <div className='footer'>
            <p>&copy;2022 ねこみみシッター &copy;2022 Syuji</p>
        </div>
        )
    }
}
