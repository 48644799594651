import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header>
                <div className='main-nav'>
                    <div className='navbar navbar-inverse'>
                        <Navbar className="navbar-expand-sm navbar-toggleable-sm" light>
                            <Container>
                                <NavbarBrand tag={Link} to="/">ねこみみシッター</NavbarBrand>
                                <NavbarToggler onClick={this.toggle} className="mr-2" />
                                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                                    <ul className="navbar-nav flex-grow">
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/about">About</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/babies">Babies</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/vract">VR Act</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/link">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdU63BTlxOW9dIwqdrq0cAs1hHalpdQDf91wXfdrQhg7Bj2Dw/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer" className="text-dark nav-link">
                                                Mail
                                            </a>
                                        </NavItem>
                                    </ul>
                                </Collapse>
                            </Container>
                        </Navbar>
                    </div>
                </div>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
